import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class EquipmentBreakdownInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Equipment Breakdown Insurance - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "Equipment Breakdown Insurance is designed to cover three main categories that are excluded under a typical commercial property policy, including but not limited to electrical damage, mechanical breakdown, and boiler explosion. Contact us, your Baton Rouge independent insurance agency, today!" }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} alt="Worker using company equipment (drill)" />
        <Callout
          title="Equipment Breakdown Insurance"
          content="For many Louisiana businesses, their equipment is vital to their operation. And even with proper installation and maintenance things stop working properly. When mechanical or electrical failures happen make sure you have Equipment Breakdown Insurance in place to address financial loss associated with that piece of machinery or equipment. Equipment Breakdown Insurance is designed to cover three main categories that are excluded under a typical commercial property policy, including but not limited to electrical damage, mechanical breakdown, and boiler explosion."
        />
        <div className="site-body page-content">
          <h2>What Equipment Breakdown Insurance Usually Covers</h2>
          <p>
          <ul>
          <li><strong>Repair or Replace.</strong> This covers cost to repair or replace a damaged piece of machinery or equipment, including parts and labor.</li>
          <li><strong>Lost income.</strong> This will help compensate for some income loss from downtime from a certain piece of equipment.</li>
          <li><strong>Spoilage.</strong> Covers spoilage damage to raw materials, products being manufactured or finished products.</li>
        </ul>
          </p>
          <DidYouKnow
            fact="46% of equipment breakdown losses are electrical related, 38% are mechanical, and 16% are due to a loss in pressure."
            source="Insurance Journal"
          />
          <h2>What Equipment Breakdown Insurance Usually Does Not Cover</h2>
          <p>
          <ul>
          <li>Ordinary wear and tear.</li>
          <li>Equipment breakdown from poor maintenance.</li>
          <li>Ensuing losses resulting from electrical, mechanical, or boiler explosion such as fire, bodily injury or other property damage.</li>
        </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="an Equipment Breakdown Insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default EquipmentBreakdownInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "equipment-breakdown-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-2.jpg" }) {
      ...mainBg
    }
  }
`;
